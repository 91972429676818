<template>
  <section class="about-slider">
    <ul class="about-slider__wrap" v-if="desktop === true">
      <li
        class="about-slider__item"
        v-for="(item, index) in data"
        @mouseover="activeLink = index"
        v-on:click.prevent="activeLink = index"
        :class="{ active: index == activeLink }"
        :key="item.id"
      >
        <div class="about-slider__img">
          <v-lazy-image
            :src="url + item.image"
            rel="preload"
            width="583"
            height="700"
            :alt="item.name"
          />
        </div>
        <div class="about-slider__descr">
          <h2 class="about-slider__name">{{ item.name }}</h2>
          <p class="about-slider__position">{{ item.role }}</p>
          <router-link
            :to="{ name: 'AboutCompanyChildren', params: { name: item.slug } }"
            class="about-slider__link"
          >
            {{ t("details") }}
          </router-link>
        </div>
      </li>
    </ul>

    <swiper
      v-if="mobile === true"
      :navigation="true"
      :loop="true"
      :slidesPerView="1"
      :speed="2000"
      :centeredSlides="true"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      :breakpoints="{
        '576': {
          slidesPerView: 2,
        },
        '768': {
          slidesPerView: 3,
        },
      }"
    >
      <swiper-slide v-for="item in data" :key="item.id">
        <div class="about-slider__img">
          <v-lazy-image
            :src="url + item.image"
            rel="preload"
            width="163"
            height="327"
            :alt="item.name"
          />
        </div>

        <div class="about-slider__descr">
          <h2 class="about-slider__name">{{ item.name }}</h2>
          <p class="about-slider__position">{{ item.role }}</p>
          <router-link
            :to="{ name: 'AboutCompanyChildren', params: { name: item.slug } }"
            class="about-slider__link"
          >
            {{ t("details") }}
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import VLazyImage from "v-lazy-image";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

// Import Swiper styles
import "swiper/swiper.scss";

import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

export default defineComponent({
  components: {
    Swiper,
    VLazyImage,
    SwiperSlide,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    data: {
      type: Array,
    },
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      window: {
        width: 0,
      },
      desktop: true,
      mobile: false,
      show: false,
      activeLink: 1,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 1024) {
        (this.desktop = true), (this.mobile = false);
      } else if (this.window.width < 1024) {
        (this.desktop = false), (this.mobile = true);
      }
    },
    activeSlide() {
      this.show = true;
    },
  },
});
</script>

<style lang="scss">
@import "~@/assets/styles/_mixins.scss";

.about-slider {
  @media (min-width: 1024px) {
    background: url("~@/assets/images/about-company.jpg") no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  @media (max-width: 1023.98px) {
    padding: 0 78px;

    .swiper-slide {
      height: 100%;
      transition: width 0.3s linear;

      &:not(.swiper-slide-active) {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgba(#1c1c1c, 0.4);
          transition: all 0.3s linear;
        }
      }
    }

    .swiper-container {
      overflow: initial;

      &::before {
        content: "";
        position: absolute;
        left: -78px;
        top: 0;
        height: 100%;
        width: 78px;
        background: #fff;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        right: -78px;
        top: 0;
        height: 100%;
        width: 78px;
        background: #fff;
        z-index: 2;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      width: 45px;
      height: 45px;
      z-index: 99;
      cursor: pointer;
      transition: all 0.3s linear;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("~@/assets/images/arrow-slider.svg") no-repeat;
        background-size: 100% 100%;
      }
    }

    .swiper-button-prev {
      left: -60px;
    }

    .swiper-button-next {
      right: -60px;
      transform: rotate(180deg) translateY(50%);
    }
  }

  @media (max-width: 575.98px) {
    padding: 0 50px;

    .swiper-container {
      &::before {
        left: -50px;
        width: 50px;
      }

      &::after {
        right: -50px;
        width: 50px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 25px;
      height: 25px;
    }

    .swiper-button-prev {
      left: -38px;
    }

    .swiper-button-next {
      right: -38px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
    display: flex;
    max-width: 206px;
    width: 100%;
    transition: all 0.3s linear;

    @media (min-width: 1024px) {
      height: 700px;
    }

    &.active {
      max-width: 573px;
      width: calc(100% - (11% * 4));
    }

    &:not(.active) {
      cursor: pointer;
      min-width: 130px;
      width: 11%;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(#1c1c1c, 0.4);
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__descr {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 83%;
    width: fit-content;
    transition: all 0.3s linear;

    @media (max-width: 1300px) {
      max-width: 100%;
    }

    .active & {
      padding: 30px;
      background: rgba(#1c1c1c, 0.4);
    }
  }

  &__name {
    color: #f9b80e;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.8px;
    padding-left: 20px;
    @include property("padding-left", 20, 10, true, 1670, 1024);
    border-left: 2px solid #f9b80e;

    .active & {
      font-size: 32px;
      @include property("font-size", 32, 16, true, 1366, 1024);
      line-height: calc(42 / 32);
      letter-spacing: 1.6px;
    }
  }

  &__position {
    text-align: center;
    font-size: 18px;
    line-height: calc(30 / 18);
    margin-top: 20px;
    margin-bottom: 15px;
    @include property("margin-top", 20, 10, true, 1366, 1024);
    @include property("margin-bottom", 15, 5, true, 1366, 1024);
    color: #e1e1e1;
    display: none;

    .active &,
    .swiper-slide-active & {
      display: block;
    }
  }

  &__link {
    align-self: center;
    font-size: 16px;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
    display: none;

    .active &,
    .swiper-slide-active & {
      display: block;
    }
  }
}
</style>
